export class Color {
	public red: number;
	public green: number;
	public blue: number;

	constructor(red: number, green: number, blue: number) {
		this.red = red;
		this.green = green;
		this.blue = blue;
	}
}